import React, { useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./UseCases.css";

const UseCases = () => {
  const { useCaseName, region, code, customer, cardname } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { listOfReportCategories, customerLogo } = location.state || {};
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  document.title = `${lastSegment}`;

  // console.log(location);


  const reportCategoryVariants = {
    initial: { opacity: 0, y: 50 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.1,
        ease: "easeOut",
        delay: 0.1  // Reducing delay for quicker animations
      }
    },
    // hover: {
    //   scale: 1,
    //   transition: {
    //     duration: 0.05,
    //     ease: "easeInOut"
    //   }
    // },
    exit: { opacity: 0, y: -50, transition: { duration: 0.1, ease: "easeIn" } },
  };

  const handleNavigation = (category) => {
    if (category.embedCode) {
      navigate(`/dashboard/${category.reportCategory}/${category.embedCode}`);
      return;
    }
    const path = `${location.pathname}/${category.reportCategory.replaceAll(" ", "-")}`;
    navigate(path, {
      state: {
        endReports: category.endReports,
        customerLogo,
        useCaseName,
        reportCategory: category.reportCategory,
        listOfReportCategories: category.listOfReportCategories,
      },
    });
  };


  const handleGoBack = () => {
    navigate(-1);
  };

  const openHomePage = () => {
    navigate("/");
  }

  return (
    <div>
      <div className="usercaseBackgr">
        <div className="customize-container">
          <div className="flex-userTitle">
            <div className="nav-bar">
              <div className="w-75 main-logo" onClick={openHomePage}>
                <img
                  className="Business-logo"
                  src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
                  alt="Business Logo"
                />
              </div>
              <img
                className="right-side-logo"
                src={customerLogo}
                alt="Customer Logo"
              />
            </div>
            <div
              className="subredarrowBackBtn d-flex align-items-center"
              onClick={handleGoBack}
            >
              <i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
              <p className="BackBtn p-0 m-0">Back</p>
            </div>
            <div className="useCaseName-display">
              <p className="text-white userCase-p">{useCaseName}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="usercaseLowerBack">
        <div className="customize-container">
          <div className="report-categories-container">
            {listOfReportCategories &&
              listOfReportCategories.map((category, index) => (
                <motion.div
                  key={index}
                  className={`report-category-card ${category.reportCategoryIcon ? "filled-icon" : "empty-icon"} ${index % 2 === 0 ? "card-alt-color" : ""} ${hoveredIndex !== null && hoveredIndex !== index ? "faded" : ""}`}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  transition={{ duration: 0.1, ease: "easeOut", delay: index * 0.1 }}
                  variants={reportCategoryVariants}
                  onClick={() => handleNavigation(category)}
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <div className="category-card-below">
                    {category.reportCategoryIcon ? (
                      <img
                        className="report-category-icon"
                        src={category.reportCategoryIcon}
                        alt={`Icon for ${category.reportCategory}`}
                      />
                    ) : (
                      <p className="empty-category-text p-0 m-0">
                        {category.reportCategory}

                      </p>
                    )}
                    <p
                      className="report-category-name-usercase"
                      style={{
                        display: category.reportCategoryIcon ? "block" : "none",
                      }}
                    >
                      {category.reportCategory}
                    </p>
                  </div>
                </motion.div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UseCases;
