import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import regionsData from "../../LocalData/data.json";
import "./homepage.css";

const Home = () => {
	const navigate = useNavigate();
	const [regions, setRegions] = useState([]);
	const [isNorthAmericaEnabled, setIsNorthAmericaEnabled] = useState(false);
	const [isLathinAmericaEnabled, setIsLathinAmericaEnabled] = useState(false);
	const [isEuropeEnabled, setIsEuropeEnabled] = useState(false);
	const [isFranceEnabled, setIsFranceEnabled] = useState(false);
	const [isEMEAEnabled, setIsEMEAEnabled] = useState(false);
	const [isAPACEnabled, setIsAPACEnabled] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [userName, setUserName] = useState("");
	const [gender, setGender] = useState("");
	const dropdownRef = useRef(null);

	useEffect(() => {
		// console.log(regionsData);
		setRegions(regionsData.regions);

		regionsData.regions.forEach((region) => {
			// console.log(region);

			switch (region.code) {
				case "north-america":
					setIsNorthAmericaEnabled(region.enabled);
					break;
				case "latin-america":
					setIsLathinAmericaEnabled(region.enabled);
					break;
				case "europe":
					setIsEuropeEnabled(region.enabled);
					break;
				case "france-operation":
					setIsFranceEnabled(region.enabled);
				case "emea":
					setIsEMEAEnabled(region.enabled);
					break;
				case "apac":
					setIsAPACEnabled(region.enabled);
					break;
				default:
					break;
			}
		});
		const storedName = localStorage.getItem("Name");
		if (storedName) {
			setUserName(storedName);
		}
		const storedGender = localStorage.getItem("Gender");
		if (storedGender) {
			setGender(storedGender);
		}
		// console.log(storedGender, "storedGender");

		// Close dropdown if clicked outside
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsDropdownOpen(false);
			}
		};
		document.addEventListener("click", handleClickOutside);
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);



	const handleRegionClick = (region, isSubRegion = false) => {
		if (isSubRegion) {
			// console.log(`Clicked on sub-region: ${region.region}, Code: ${region.code}`);

			navigate(`/regions/${region.code}`, {
				state: { subRegions: region.subRegions },
			});

		} else {
			// console.log(`Clicked on region: ${region}`);
			const selectedRegion = regions.find(
				(r) => r.code === region.toLowerCase()
			);
			navigate(`/regions/${selectedRegion.code}`, {
				state: { subRegions: selectedRegion.subRegions },
			});
		}
	};

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleLogout = () => {
		localStorage.removeItem("authToken");
		localStorage.removeItem("Name");
		localStorage.removeItem("Gender");
		window.location.reload();
	};

	document.querySelectorAll('.square-map').forEach((element) => {
		element.addEventListener('mouseenter', () => {
			document.querySelectorAll('.square-map').forEach((sibling) => {
				if (sibling !== element) {
					sibling.querySelector('.square-map-text').style.color = 'rgba(255, 255, 255, 0.5)';
				}
			});
		});

		element.addEventListener('mouseleave', () => {
			document.querySelectorAll('.square-map-text').forEach((text) => {
				text.style.color = 'rgba(255, 255, 255, 1)';
			});
		});
	});
	const openHomePage = () => {
		navigate("/");
	}

	const [phrases] = useState([
		"SINGLE PANE OF GLASS",
		"SINGLE SOURCE OF TRUTH",
		"ORANGE BUSINESS CLOUD"
	]);
	const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
	const [displayText, setDisplayText] = useState("");
	const [currentLetterIndex, setCurrentLetterIndex] = useState(0);

	useEffect(() => {
		const currentPhrase = phrases[currentPhraseIndex];

		if (currentLetterIndex < currentPhrase.length) {
			const typingTimeout = setTimeout(() => {
				setDisplayText(prev => prev + currentPhrase[currentLetterIndex]);
				setCurrentLetterIndex(currentLetterIndex + 1);
			}, 100);

			return () => clearTimeout(typingTimeout);
		} else {
			const phraseTimeout = setTimeout(() => {
				setDisplayText("");
				setCurrentLetterIndex(0);
				setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
			}, 2000);

			return () => clearTimeout(phraseTimeout);
		}
	}, [currentLetterIndex, currentPhraseIndex, phrases]);

	// In JSX:
	<h1 className="animated-text">{displayText}</h1>

	const avatarImage = gender === 'Male'
		? "https://icons.veryicon.com/png/o/miscellaneous/user-avatar/user-avatar-male-5.png"
		: gender === 'Female'
			? "https://cdn-icons-png.flaticon.com/512/417/417776.png"
			: "https://icons.veryicon.com/png/o/miscellaneous/user-avatar/user-avatar-male-5.png"; 

	return (
		<div>
			<div className="background-img">
				<div className="customize-container">
					<div className="d-flex justify-content-between content align-items-center">
						<div className="d-flex flex-column justify-content-between align-items-start w-25 left-side">
							<div onClick={openHomePage} className="main-logo">
								<img
									id="first-logo"
									className="top-item logo-image"
									src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
									alt="Orange Business Logo"
								/>
							</div>
							<div>
								<div className="d-flex flex-column align-items-start justify-content-between global-section">
									<div>
										<p className="p-0 m-0 text-white global-text">
											GLOBAL
										</p>
									</div>
									<h1 className="animated-text">{displayText}</h1>
									<div>
										<img
											src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+Global+SPOG+dashboard+-+assets/slice2.png"
											alt="Slice"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="right-side w-75">
							<div>
								<div className="world-background">
									<div
										className={`square-map NorthAmerica ${isNorthAmericaEnabled
											? "enabled"
											: "disabled"
											}`}
										onClick={
											isNorthAmericaEnabled
												? () =>
													handleRegionClick(
														"north-america"
													)
												: null
										}
									>
										<div className="corner-bottom-left"></div>
										<div className="corner-bottom-right"></div>
										<span className="square-map-america square-map-text">
											North America
										</span>
									</div>

									<div
										className={`square-map Latin latinAmerica ${isLathinAmericaEnabled
											? "enabled"
											: "disabled"
											}`}
										onClick={
											isLathinAmericaEnabled
												? () =>
													handleRegionClick(
														"latin-america"
													)
												: null
										}
									>
										<div className="corner-bottom-left"></div>
										<div className="corner-bottom-right"></div>
										<span className="square-map-Latin-America square-map-text">
											Latin America
										</span>
									</div>

									<div
										className={`square-map Europe ${isEuropeEnabled
											? "enabled"
											: "disabled"
											}`}
										onClick={
											isEuropeEnabled
												? () =>
													handleRegionClick(
														"Europe"
													)
												: null
										}
									>
										<div className="corner-bottom-left"></div>
										<div className="corner-bottom-right"></div>
										<span className="square-map-Europe square-map-text">
											Europe
										</span>
									</div>

									<div
										className={`square-map France ${isFranceEnabled
											? "enabled"
											: "disabled"
											}`}
										onClick={
											isFranceEnabled
												? () =>
													handleRegionClick(
														"France-Operation"
													)
												: null
										}
									>
										<div className="corner-bottom-left"></div>
										<div className="corner-bottom-right"></div>
										<span className="square-map-France square-map-text">
											French Operations
										</span>
									</div>

									<div
										className={`square-map EMEA ${isEMEAEnabled
											? "enabled"
											: "disabled"
											}`}
										onClick={
											isEMEAEnabled
												? () =>
													handleRegionClick(
														"EMEA"
													)
												: null
										}
									>
										<div className="corner-bottom-left"></div>
										<div className="corner-bottom-right"></div>
										<span className="square-map-EMEA square-map-text">
											EMEA
										</span>
									</div>

									<div
										className={`square-map APAC ${isAPACEnabled
											? "enabled"
											: "disabled"
											}`}
										onClick={
											isAPACEnabled
												? () =>
													handleRegionClick(
														"APAC"
													)
												: null
										}
									>
										<div className="corner-bottom-left"></div>
										<div className="corner-bottom-right"></div>
										<span className="square-map-text square-map-APAC">
											APAC
										</span>
									</div>

								</div>
							</div>
							<div
								className="d-flex set-logout-avtar"
								onClick={toggleDropdown}
								ref={dropdownRef}
							>
								<div className="Log-out">
									<img
										className="user-avtar"
										src={avatarImage}
										alt="User Avatar"
									/>
								</div>

								{isDropdownOpen && (
									<ul className="dropdown-menu">
										<li
											className="dropdown-item"
										>
											<p className="p-0 m-0">{userName}</p>
										</li>
										<li
											className="dropdown-item"
											onClick={handleLogout}
										>
											Logout
										</li>
									</ul>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
