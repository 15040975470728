import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import "./customer.css";

const Customer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { listOfuseCases, customerLogo } = location.state || {};

  const [hoveredIndex, setHoveredIndex] = useState(null); // Track which card is hovered

  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  const capitalizedRegion =
    lastSegment.charAt(0).toUpperCase() + lastSegment.slice(1);
  document.title = `${capitalizedRegion}`;

  const handleCardClick = (useCase) => {
    if (useCase.enabled) {
      const useCaseName = useCase.useCaseName.replaceAll(" ", "-");
      const currentPath = location.pathname;

      if (useCase.domo === 'orange-domo') {
        const newPath = `/self-service-platforms`;
        navigate(newPath, {
          state: {
            listOfReportCategories: useCase.listOfReportCategories,
            customerLogo: customerLogo,
            useCaseName: useCase.useCaseName,
            reportCategory: useCase.reportCategory,
          }
        });
      } else {
        const newPath = `${currentPath}/${useCaseName}`;
        navigate(newPath, {
          state: {
            listOfReportCategories: useCase.listOfReportCategories,
            customerLogo: customerLogo,
          }
        });
      }
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const useCaseCardVariants = {
    initial: { opacity: 0, y: 50 },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.1,
        ease: "easeOut",
        delay: 0,
      },
    }
  };

  const openHomePage = () => {
    navigate("/");
  }

  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <div className="customerBackgr">
        <div className="customize-container customize-height">
          <div className="nav-bar">
            <div className="w-75 main-logo" onClick={openHomePage}>
              <img
                className="Business-logo"
                src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
                alt="Business Logo"
              />
            </div>
            <img
              className="right-side-logo"
              src={customerLogo}
              alt="Customer Logo"
            />
          </div>
          <div
            className="subredarrowBackBtn d-flex align-items-center"
            onClick={handleGoBack}
          >
            <i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
            <p className="BackBtn p-0 m-0">Back</p>
          </div>
          <p className="single-pane-text">Single Pane of Glass</p>
        </div>
      </div>

      
      <div className="lowerBackImg">
       <div className="customize-container">
       <div className="card-center">
          <div className="useCaseContainer d-flex">
            {listOfuseCases &&
              listOfuseCases.map((useCase, index) => (
                <motion.div
                  key={index}
                  className={`useCaseCard ${useCase.enabled ? "enabled-card" : "disabled-card"} ${hoveredIndex !== null && hoveredIndex !== index ? "faded" : ""}`}
                  onClick={() => handleCardClick(useCase)}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.2,
                    ease: "easeOut",
                    delay: index * 0.05,
                  }}
                  variants={useCaseCardVariants}
                >
                  <div className="card-desgin">
                    <img
                      className="card-icon"
                      src={useCase.useCaseIcon}
                      alt={`Icon for ${useCase.useCaseName}`}
                    />
                    <p className="useCaseName">{useCase.useCaseName}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </div>
       </div>
      </div>
    </div>
  );
};

export default Customer;
