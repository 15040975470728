import React, { useState } from "react";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import "./UseCasesDetail.css";

const UseCasesDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [hoveredSubSectionIndex, setSubSectionHoveredIndex] = useState(null);
	// console.log(location, "location data");

	// Safe destructuring with fallback empty arrays
	const {
		endReports = [],
		customerLogo,
		useCaseName,
		reportCategory,
		listOfReportCategories = [], // Fallback to empty array if undefined
	} = location.state || {};

	

	const [selectedCategory, setSelectedCategory] = useState(null);

	// Handle Report Click
	const handleReportClick = (dashboardName, embedCode, domo, reportName, directLink) => {
		if (domo) {
			navigate(`/self-service-platform/${encodeURIComponent(reportName)}`, {
				state: {
					reportName: reportName,
					directLink: directLink,
				},
			});
		} else {
			navigate(`/dashboard/${dashboardName}/${embedCode}`);
		}
	};

	// Handle Category Click
	const handleCategoryClick = (category) => {
		// console.log("Category clicked:", category);
		setSelectedCategory((prevCategory) => (prevCategory === category ? null : category));
	};

	const reportCardVariants = {
		initial: { opacity: 0, y: 50 },
		animate: {
			opacity: 1,
			y: 0,
			transition: {
				duration: 0.2,
				ease: "easeOut",
				delay: 0.2,
			},
		},
		// hover: {
		// 	scale: 1.05,
		// 	transition: { duration: 0.1, ease: "easeInOut" },
		// },
		exit: {
			opacity: 0,
			y: -50,
			transition: { duration: 0.1, ease: "easeIn" },
		},
	};

	const openHomePage = () => {
		navigate("/");
	}

	return (
		<div>
			<div className="usercaseBackgr">
				<div className="customize-container">
					<div className="flex-userTitle">
						<div className="nav-bar">
							<div className="w-75 main-logo" onClick={openHomePage} >
								<img
									className="Business-logo"
									src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
									alt="Business Logo"
								/>
							</div>
							<img
								className="right-side-logo"
								src={customerLogo}
								alt="Customer Logo"
							/>
						</div>
						<div
							className="subredarrowBackBtn d-flex align-items-center"
							onClick={() => navigate(-1)}
						>
							<i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
							<p className="BackBtn p-0 m-0">Back</p>
						</div>
						<div className="useCaseName-display">
							<p className="text-white userCase-p">
								{reportCategory}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="usercaseLowerBack-usercase">
				<div className="customize-container">
					{/* Safe check for empty endReports */}
					{endReports && endReports.length > 0 && (
						<div className="endReports-card">
							{endReports.map((report, index) => (
								<motion.div
									key={`endReports-${index}`}
									className={`report-category-card filled-icon ${index % 2 === 0 ? "card-alt-color" : ""} 
								${hoveredIndex !== null && hoveredIndex !== index ? "faded" : ""}`}
									onClick={() =>
										handleReportClick(
											report.reportName.replaceAll(" ", "-"), // Pass encoded report name
											report.embedCode,
											report.domo,
											report.reportName, // Pass reportName
											report.direct_link  // Pass directLink
										)
									}
									onMouseEnter={() => setHoveredIndex(index)}
									onMouseLeave={() => setHoveredIndex(null)}
									initial={{ opacity: 0, y: 50 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.3, ease: "easeOut", delay: index * 0.1 }}
									exit="exit"
									variants={reportCardVariants}
								>
									<div className="category-card-below">
										{report.reportIcon ? (
											<img
												className="report-category-icon"
												src={report.reportIcon}
												alt={`Icon for ${report.reportName}`}
											/>
										) : (
											<p className="empty-category-text p-0 m-0">
												{report.reportName}
											</p>
										)}
										<p className="report-category-name-usercase">{report.reportName}</p>
									</div>
								</motion.div>
							))}
						</div>
					)}
					{/* Safe check for empty listOfReportCategories */}
					{listOfReportCategories && listOfReportCategories.length > 0 && (
						<div className="endReports-card">
							{listOfReportCategories.map((category, index) => (
								<motion.div
									key={`listOfReportCategories-${index}`}
									className={`report-category-card filled-icon-user-case ${index % 2 === 0 ? "card-alt-color-user-case" : ""}
								${hoveredIndex !== null && hoveredIndex !== index ? "faded" : ""}`}
									onClick={() => {
										handleCategoryClick(category);
									}}
									onMouseEnter={() => setHoveredIndex(index)}
									onMouseLeave={() => setHoveredIndex(null)}
									initial={{ opacity: 0, y: 50 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.3, ease: "easeOut", delay: index * 0.1 }}
									exit="exit"
									variants={reportCardVariants}
								>
									<div className="category-card-below">
										<div className="ThousandEyes">
											<div>
												<p className="text-white powered-by">{category.poweredBy}</p>
											</div>
											<div>
												<img
													className="report-category-icon-user-case"
													src={category.reportIcon}
													alt={`Icon for ${category.reportCategory}`}
												/>
											</div>
											<div>
												<p className="report-category-name">{category.reportCategory}</p>
											</div>
										</div>
									</div>
								</motion.div>
							))}
						</div>
					)}
					{/* Safe check for selectedCategory */}
					{selectedCategory && selectedCategory.endReports && selectedCategory.endReports.length > 0 ? (
						<div className="endReports-card">
							{selectedCategory.endReports.map((report, index) => (
								<motion.div
									key={`selectedReport-${index}`}
									className={`lower-category-card selectedCategory-card filled-icon selected-card ${index % 2 === 0 ? "card-alt-color" : ""}
								${hoveredSubSectionIndex !== null && hoveredSubSectionIndex !== index ? "faded" : ""}`}
									onClick={() =>
										handleReportClick(
											report.reportName.replaceAll(" ", "-"),
											report.embedCode,
											report.domo,
											report.reportName,
											report.direct_link
										)
									}
									onMouseEnter={() => setSubSectionHoveredIndex(index)}
									onMouseLeave={() => setSubSectionHoveredIndex(null)}
									initial="initial"
									animate="animate"
									whileHover="hover"
									exit="exit"
									variants={reportCardVariants}
								>
									<div className="selectedCategory-card-below">
										<p className="selectedCategory-category-name p-0 m-0">{report.reportName}</p>
									</div>
								</motion.div>
							))}
						</div>
					) : (
						<p></p>
					)}
				</div>
			</div>
		</div>
	);
};

export default UseCasesDetail;
