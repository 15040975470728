import React, { useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";


const Domo = () => {
  const { reportName } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { directLink, reportName: stateReportName } = location.state || {};

  const finalReportName = stateReportName || reportName;
  const finalDirectLink = directLink || "No direct link available";

  if (!location.state) {
    return <p>No report data available.</p>;
  }

  document.title = reportName ? `${reportName} - Dashboard` : "Self-Service Platform";

  const handleGoBack = () => {
    navigate(-1);
  };

  const openHomePage = () => {
    navigate("/");
  }
  
  return (
    <div>
      <div className="dashboard-nav-bar d-flex justify-content-between align-items-center">
        <div>
          <div
            className="Dashbord-Back-Btn d-flex align-items-center"
            onClick={handleGoBack}
          >
            <i className="fa-sharp fa-solid fa-arrow-left-long btn-icon"></i>
            <p className="BackBtn p-0 m-0">Back</p>
          </div>
        </div>
        <div>
          <div className="w-75 main-logo" onClick={openHomePage}>
            <img
              className="Business-logo"
              src="https://manzeera-public-data.s3.ap-south-1.amazonaws.com/SPOG/SKF-SPOG/OB+SPOG+Dashboard+-+assets/Orange_Business_RGB_Master_Logo_White_Text.svg.png"
              alt="Business Logo"
            />
          </div>
        </div>
      </div>

      <div className="iframe-container">
        <iframe
          src={finalDirectLink}
          allowFullScreen={true}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          crossOrigin="anonymous"
        ></iframe>
      </div>
    </div>
  );
};

export default Domo;




